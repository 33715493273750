.MuiDataGrid-cell--withRenderer{
    .options{
        button{
            padding: 4px 12px;
            font-size: 12px;
            border: 1px solid #000;
            background: none;
            cursor: pointer;
            &:hover{
                border: 0;
                background: #024b02;
                color: #fff;
            }
        }
    }
}