.recepisse-details{
    width: 90%;
    padding: 1.5px;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 10px;
    position: relative;
    &__container{
        display: flex;
        flex-direction: column;
        gap: 8px;
    }
    
    &__header-1{
        background: #0d0d0d;
        color: #fff;
        padding: 10px 32px;
        display: flex;
        justify-content: space-between;
        &__left{
            max-width: 365px;
            .title-1{
                font-size: 14px;
                text-transform: uppercase;
                text-align: center;
                margin-bottom: 10px;
                font-weight: 800;
            }
            .title-2{
                font-size: 12px;
                text-align: center;
                text-transform: uppercase;
                font-weight: 600;
            }
        }
        &__center{
            flex: 1;
            display: flex;
           
            img{
                margin-left: 12px;
                width: 60px;
            }
        }
        &__right{
            padding-right: .4em;
            .title-1{
                text-align: center;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 800;
            }
            .title-2{
                text-align: center;
            }
            .title-2 span{
               font-size: 10px;
               text-transform: uppercase;   
            }
            .title-2 span:nth-child(1){
                color: #ce1111;
            }
            .title-2 span:nth-child(2){
                color: #f4f414;
            }
            .title-2 span:nth-child(3){
                color: #008000;
            }
        }
    }
    &__button{
      
        button{
            padding: 7px 22px;
            background: transparent;
            border: 1px solid #000;
            position: relative;
            transition: all .4s ease-in-out;
           
            cursor: pointer;
            &:not(.no-ponter):hover{
                background: #000;
                color: #fff;
            }
            &:first-child{
                margin-right: 5px;
            }
           
        //   &::before{
        //     content: url("../../assets/images/logo/print.png");
        //     display: inline-block;
        //     position: absolute;
        //     width: 10px; 
        //   }
       
        }
       
       
    }
    &__header-2,&__header-3{
        .title-1, .title-2{
           text-transform: uppercase;
           text-align: center;
           font-size: 22px;
           font-weight: 700;
           color: #7b7c79;
        }
    }
    &__header-3{
        .title-1 {
           color: #0d0d0d;
        }
        .title-2{
            font-size: 14px;
            padding-top: 5px;
            color: #ce1111;
        }
    }
    &__line{
        height: 1.5px;
        background: #7b7c79;
        margin-top: 10px;
    }

    //details des inputs
    &__values{
        margin-top: 1em;
        font-family: "Lora";
        padding: 20px 20px;
       
        &::before{
            content: "";
            background-image: url('http://portieres.dsdguinee.com/police.jpeg');
            background-size: cover;
            background-size:780px 860px;
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            top: 120px;
            right: 0px;
            bottom: 0px;
            left: .4em;
            opacity: 0.1;
         }
      
        &__reference{
            display: flex;
            flex-direction: column;
            gap: 4px;
            &__label{
                color: #7b7c79;
                text-transform: uppercase;
                &__values{
                    text-transform: uppercase;
                    font-weight: 700;
                    letter-spacing: .7px;
                }
            }
        }

        &__immatriculation{
            display: flex;
            margin-top: 1em;
            flex-wrap: wrap;
            justify-content: space-between;
            &__number, &__type-autorisation{
                display: flex;
                flex-direction: column;
                gap: 10px; 
                &__label,&__values{
                    text-transform: uppercase;
                    font-weight: 600;
                    color: #7b7c79;
                }
                &__values{
                    color: #0d0d0d;
                    padding: 5px 8px;
                    max-width: 160px;
                    border: 2px solid #0d0d0d;
                    text-align: center;
                    font-size: 18px;
                    letter-spacing: 1px;
                    border-radius: 5px;
                }
            }

            &__type-autorisation{
                &__values{
                    color: #0d0d0d;
                    padding: 0;
                    max-width: 380px;
                    border: 0;
                    text-align: left;
                    font-size: 18px;
                    letter-spacing: 1px;
                    border-radius: 0;
                    font-weight: 400;
                }
            }
        }

        &__proprietaire,&__info-vehicule{
            display: flex;
            margin-top: 1em;
            flex-direction: column;
            &__title-1{
              width: 100%;
            }
            &__title-1 div:nth-child(1){
                text-transform: uppercase;
                color: #7b7c79;
                margin-bottom: 8px;
                font-weight: 800;
                font-size: 18px;
            }
            &__title-1 div:nth-child(2) {
                height: 4px;
                background: #bbbcb8;
                // width: 92%;
            }
            &__info{
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1em;
                padding-right: 3em;
                padding-bottom: .5em;
                 flex-wrap: wrap;
                .chassis,.vehicule{
                    text-transform: uppercase;
                    letter-spacing: 1px;
                 }
                &__nom,&__adresse{
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                  
                    &__label{
                        font-size: 16px;
                        text-transform: uppercase;
                        color: #7b7c79;
                        margin-top: 10px;
                        
                    }
                    &__values{
                        font-size: 16px;
                        text-transform: capitalize;
                        font-weight: 500;
                    
                    }
                }
                &__adresse{
                  
                }  
            }
            
            
           
        }

        &__info-vehicule{

        }
    }

    //signaires

    &__signataire{
        display: flex;
        justify-content: space-between;
        padding: 0px 20px;
        margin-top: 1em;
        &__date,&__nom{
            display: flex;
            flex-direction: column;
            gap: 10px;
            &__label{
                color: #0d0d0d;
                text-transform: uppercase;
                font-weight: 600;
                letter-spacing: 1px;
                &__values{
                    text-transform: uppercase;
                    font-weight: 700;
                    letter-spacing: .7px;
                }
            }
        }
    }

    //pied de page
    &__pied{
        display: flex;
        gap: 3em;
        justify-content: center;
        margin-top: 1.5em;
        align-items: center;
        &__matrix{
            width: 75px;
            img{
                width: 100%;
            }
        }
        &__branding{
            width: 80px;
            img{
                width: 100%;
            }
        }
    }
    
}

@media print {
    html,
    body {
      height: 100%
      
    }
  }