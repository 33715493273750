.NavBar{
    position: sticky;
    width: 100%;
    height: 45px;
    background: #000;
    color: #fff;
    &__elements{
        height: 100%;
       ul{
         list-style: none;
         display: flex;
         flex-direction: row-reverse;
         justify-content: center;
         gap: 20px; 
         align-items: center;
         padding: 0 20px;
         height: 100%;
         li{
            button {
                padding: 8px;
                cursor: pointer;
                border: none;
                background: #f1a20f;
                color: #f9f9f9;
                position: relative;
                transform: all .3s ease-in;
                &:hover{
                    background: #e8b65b; 
                    color: #a09a9a;
                }
               
            }
            &:nth-child(3) a:hover{
                text-decoration: underline;
                text-decoration-color: #808f85;
                text-underline-offset: 5px;
            }
            &:nth-child(2) a:hover{
                text-decoration: underline;
                text-decoration-color: #808f85;
                text-underline-offset: 5px;
            }
            &:nth-child(2) a{
                color: #f9f9f9;
                font-size: 16px;
                text-decoration: none;
                transition: all .4s ease-in-out;
            }
            &:nth-child(3) a{
                color: #f9f9f9;
                font-size: 16px;
                text-decoration: none;
                transition: all .4s ease-in-out;
            }
         }
       }
    }
}