.recepisse{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    &__input-group{
        display: flex;
        flex-direction: column;
        gap: .5em;

        &__label{
            font-size: 20px;
            font-family: "Lora";
            font-weight: 500;
        }
        &__input-value > input{
            padding: 8px 16px;
            width: 250px;
            outline: none;
            border: 1px solid #000;
            font-size: 16px;
            font-weight: 300;
        }
        &__input-value > input::placeholder{
            font-size: 14px;
            font-family: "Inter";
        }
        &__input-value > input:focus{
            outline: 3px ridge rgba(195, 157, 42, 0.6);
            border-radius:2px;
            border: 0;
        }
    }
    
    &__button{
      margin-top: 20px;
      button{
        padding: 7px 18px;
        background: transparent;
        border: 1px solid #000;
        transition: all .3s ease-in;
        font-family: "Lora";
        font-weight: 500;
        cursor: pointer;
      }
      button:not(.no-pointer):hover{
        background: #000;
        color: #fff;
        border: 0;
      }
      .no-pointer{
        cursor:inherit;
        pointer-events: none; 
        background: #e8e8e8;
        color: #101010;
        border: none;
     }
      
    }
    .footer > p {
        color: #000;
    }
    
}