.login{
  
    background-color: var(--login-background-page);
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;

    width: 100%;
    height: 100%;
   
    &__form{
        width: 330px;
        // height: 420px;
        form{
            width: 100%;
            padding: 20px;
        }
        box-shadow: 1px 2px 13px rgba(7, 7, 7, 0.26);
        background: white;
        display: flex;
        justify-content: center;
     
        &__heading{
            display: flex;
            flex-direction: column;
            justify-content: center;
            margin-top: 1.4rem;
            .logo{
              width: 120px;
              margin: auto;
              img{
                width: 100%;
              }
            }
            .title{
                text-align: center;
                margin-top: .8rem;
                color: #333333;
                h3 {
                    font-size: 2rem;
                }
            }
        }
        &__body{
            margin-top: 20px;
            .input-group{
                display: grid;
                grid-template-columns: repeat(auto-fill,minmax(220px,1fr));
                grid-gap: .5em;
                justify-items: start;
                width: 100%;
                div {
                    display: flex;
                    justify-content: space-between;
                    flex-direction: column;
                    align-items: flex-start;
                    width: 100%;
                    gap: .6em;
                    .label {
                        margin-right: 10px;
                        font-family: 'Lora';
                    }
                    .div-input > input {
                       padding: 6px;
                       width: 100%;
                       outline: none;
                       border: 1px solid #000;
                    }
                    .div-input > input::placeholder{
                        font-size: 14px;
                    }
                }
            }
            div > button {
                margin-top: 25px;
                padding: 9px;
                width: 100%;
                background: transparent;
                cursor: pointer;
                border: 1px solid #000;
                transition: all .5s ease-in-out;
                .no-pointer{
                    cursor:inherit;
                    pointer-events: none; 
                    background: #e8e8e8;
                    color: #101010;
                    border: none;
                 }
            }
            div > button:not(.no-pointer):hover{
                background-color: #000;
                color: white;
            }
        }
    }
   
}