@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;500;600;800;900&family=Kalam:wght@300;400;700&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Neucha&family=Roboto:ital,wght@0,100;1,100&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Kalam:wght@300;400;700&family=Lora:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Neucha&family=Roboto:ital,wght@0,100;1,100&display=swap');

*{
   margin: 0;
   padding: 0;
   box-sizing: border-box;
}
:root{
  --login-background-page: #2a2b2a;
}
.App {
  width: 100vw;
  height: 100vh;
}