.liste{
    width: 90%;
    padding: 14px 40px;
    &__header{
        h3{
            font-size: 24px;
            font-weight: 600;
            font-family: "Lora";
        }
    }
    &__table{
        margin: 24px 0;

        //Header
        .css-ptkaw2-MuiDataGrid-root{
            border-width: 0!important;
            border-style: none!important;
        }
        .MuiDataGrid-columnHeaders {
            background-color: #facc70;
            border: none !important;
            font-size: 0.8rem;
            border-radius: 0 !important;
        }

        // ? Separator between headers
        // .MuiDataGrid-columnSeparator {
        //   display: none !important;
        // }

        // ? Toolbar
        .MuiDataGrid-toolbarContainer {
            margin-bottom: 1em;
            gap: 1em;
            .MuiButtonBase-root {
            color: rgba(0, 0, 0, 0.677);
            letter-spacing: 0;
            font-weight: 700;
            padding: 0.5em 0.7em;
            text-transform: none;
            background-color: #facc70;;
            border-radius: 0;
            font-size: #8c8d80;
            // ? Icones
            // .css-y6rp3m-MuiButton-startIcon {
            //   color: $primary;
            // }
            }
        }

        // ? Popup colonnes
        // TODO : Find a way to make this work
        .css-aqpgxn-MuiFormLabel-root-MuiInputLabel-root {
            font-size: 14px!important;
            font-family: "Inter" !important;
        }

        // ? Table data
        .MuiDataGrid-cell {
            font-size: 14px !important;
        }
    }
}